<template>
  <div class="relative overflow-hidden min-h-screen mt-auto ">
    <div class="max-w-7xl mx-auto w-full">
      <div class="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-40">
        <svg class="hidden lg:block absolute right-0 inset-y-0 h-screen w-48 text-white transform translate-x-1/2" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
          <polygon points="50,0 100,0 50,100 0,100" />
        </svg>


        <div class="pt-6 px-4 sm:px-6 lg:px-8  sticky top-0">
          <nav class="flex items-start justify-between sm:h-10 lg:justify-start sticky top-0 h-30" label="Global">
            <div class="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
              <div class="flex items-center justify-between w-full md:w-auto">
                <a href="#">
                  <img class="h-16 w-auto" src="../../assets/logo.png" alt="Workflow">
                </a>
                <div class="-mr-2 flex items-center md:hidden">
                  <button type="button"
                          class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-400"
                          id="main-menu" aria-haspopup="true"
                          @click="mobileNavExpanded = true">
                    <span class="sr-only">Open main menu</span>
                    <!-- Heroicon name: menu -->
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="hidden md:block md:ml-10 header-container">
              <a href="#home" class="font-medium text-gray-500 hover:text-gray-900">{{ $t('nav.home') }}</a>

              <a href="#news" class="font-medium text-gray-500 hover:text-gray-900">{{ $t('news.title') }}</a>

              <a href="#benefits" class="font-medium text-gray-500 hover:text-gray-900">{{ $t('benefits.title') }}</a>

              <a href="#service" class="font-medium text-gray-500 hover:text-gray-900">{{ $t('service.title') }}</a>

              <a href="#contact" class="font-medium text-blue-400 hover:text-blue-500">{{ $t('nav.contact') }}</a>

              <a href="/downloads/anydesk-9.0.1.exe" class="anydesk-button">Remote-Support</a>
            </div>
          </nav>
        </div>


        <div class="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden" :class="[mobileNavExpanded ? 'block'  : 'hidden']">
          <div class="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div class="px-5 pt-4 flex items-center justify-between">
              <div>
                <img class="h-16 w-auto" src="../../assets/logo.png" alt="">
              </div>
              <div class="-mr-2">
                <button
                    type="button"
                    class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-400"
                    @click="mobileNavExpanded = false">
                  <span class="sr-only">Close main menu</span>
                  <!-- Heroicon name: x -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
            <div role="menu" aria-orientation="vertical" aria-labelledby="main-menu">
              <div class="px-2 pt-2 pb-3 space-y-1" role="none">
                <a href="#home" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50" role="menuitem">
                  {{ $t('nav.home') }}
                </a>

                <a href="#news" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50" role="menuitem">
                  {{ $t('news.title') }}
                </a>

                <a href="#benefits" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50" role="menuitem">
                  {{ $t('benefits.title') }}
                </a>

                <a href="#service" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50" role="menuitem">
                  {{ $t('service.title') }}
                </a>

                <a href="#contact" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50" role="menuitem">
                  {{ $t('nav.contact') }}
                </a>

                <a href="/downloads/anydesk-9.0.1.exe" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 anydesk-button-mobile" role="menuitem">
                  Remote-Support
                </a>
              </div>
            </div>
          </div>
        </div>

        <main class="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-8 lg:mt-12 lg:px-8 xl:mt-16">
          <div class="sm:text-center lg:text-left">
            <img src="../../assets/made-in-luxembourg.png" class="h-full w-44 mx-auto md:mb-10" alt="">

            <h1 class="text-4xl text-center xl:text-left font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              <div v-if="$i18n.locale === 'fr'">
                <span class="block text-blue-400 xl:inline tracking-tight">GECAMed</span>&nbsp;
                <span class="block xl:inline">{{ $t('nav.new_era') }} </span>
              </div>
              <div v-else>
              <span class="block xl:inline">{{ $t('nav.new_era') }} </span>
              <span class="block text-blue-400 xl:inline tracking-tight">GECAMed</span>
            </div>

            </h1>

            <div class="flex mt-4 text-center space-x-6 mr-4">
              <span class="flex-1 py-1 bg-gray-200 hover:bg-gray-300 rounded cursor-pointer" @click="$i18n.locale = 'de'" :class="{'text-blue-400 font-bold': $i18n.locale === 'de'}">
                {{ $t('nav.lang_de') }}
              </span>
              <span class="flex-1 py-1 bg-gray-200 hover:bg-gray-300 rounded cursor-pointer" @click="$i18n.locale = 'fr'" :class="{'text-blue-400 font-bold': $i18n.locale === 'fr'}">
                {{ $t('nav.lang_fr') }}
              </span>
            </div>

            <p class="mt-3 text-base text-center md:text-left text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0" v-html="$t('nav.text')">
            </p>
            <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
              <div class="rounded-md shadow">
                <a href="#contact" class="flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-400 hover:bg-blue-500 md:py-4 md:text-lg md:px-10">
                  {{ $t('nav.contact') }}
                </a>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
    <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
      <img class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src="../../assets/header-img.jpg" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "NavigationBar",
  data: () => ({
    mobileNavExpanded: false,
  })
}
</script>

<style scoped>

</style>